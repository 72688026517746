import * as React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import BackImage from "../images/main.jpeg";
import { blueDeep, blueMedium } from "../utils/colors";

function MainFeaturedPost(props) {
  return (
    <div
      sx={{
        position: "relative",
        color: blueMedium,
      }}
      style={{ marginTop: 30 }}
    >
      <Grid container alignItems={"center"} spacing={2}>
        <Grid item md={6} xs={12} sm={12}>
          <Box
            sx={{
              position: "relative",
            }}
          >
            <Typography
              component="h1"
              variant="h3"
              color="inherit"
              gutterBottom
              style={{
                color: blueDeep,
                fontSize: 40,
                fontWeight: 700,
                marginBottom: 35,
              }}
            >
              Que prévoit le décret BACS ?
              <br />
              Comment le mettre en application ?
            </Typography>

            <Typography
              variant="h5"
              style={{
                color: blueMedium,
                fontSize: 19,
                paddingRight: 30,
                lineHeight: "25px",
              }}
              paragraph
            >
              Le <b>décret BACS</b> (Building Automation & Controls System) met
              en place l’obligation pour les bâtiments tertiaires
              non-résidentiels, neufs et existants, de s’équiper d’un système
              d’automatisation et de contrôle. L’installation de systèmes de
              régulation automatique de la chaleur est particulièrement ciblée
              par cette nouvelle obligation.
              <br /> <br /> Le décret BACS s’inscrit dans une démarche
              d’amélioration de la performance énergétique du parc tertiaire
              français. Une opportunité pour les assujettis qui observeront leur
              consommation énergétique diminuer avec la mise en place de cette
              obligation.
            </Typography>
            <Typography
              style={{ color: blueMedium, opacity: 0.6, fontSize: 12 }}
            >
              Mis à jour en avril 2022
            </Typography>
          </Box>
        </Grid>
        <Grid item md={6} xs={12} sm={12}>
          <Box style={{  padding: 20, height: 400 }}>
            <img
              src={BackImage}
              style={{
                objectFit: "cover",
                height: "100%",
                maxWidth: "100%",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

MainFeaturedPost.propTypes = {
  post: PropTypes.shape({
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageText: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default MainFeaturedPost;
